<template>
    <div class="Railway">
      <div class="flexAll">
        <div class="flex2">
          <div>
            <div class="flex">
              <div>始发地</div>
              <div style="margin-left: 15px">
                <el-select
                  v-model="formData.develop"
                  placeholder="请选择"
                  @change="developChange"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex" style="margin-top: 30px">
              <div>目的地</div>
              <div style="margin-left: 15px">
                <el-select
                  v-model="formData.destination"
                  placeholder="请选择"
                  @change="arriveChange"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div>
            <div class="flex">
              <div>起点</div>
              <div style="margin-left: 15px">
                <el-select v-model="formData.developStation" placeholder="请选择">
                  <el-option
                    v-for="item in optionChildren"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex" style="margin-top: 30px">
              <div>终点</div>
              <div style="margin-left: 15px">
                <el-select
                  v-model="formData.destinationStation"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in option2Children"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
  
            <div class="Rbtn">
              <el-button type="primary" @click="check">查询</el-button>
              <el-button @click="clear">清空</el-button>
            </div>
          </div>
        </div>
  
        <div class="right_bor">
          <div>运费说明</div>
          <!-- <div>1.查询仅提供基本费用测算，不代表车站可以实际按此承运。</div> -->
          <div>
            查询结果仅供参考，查询线路仅为重点线路，若查询不到请拨打客服电话：0902-6970230
          </div>
          <!-- <div>0902-6970230</div> -->
        </div>
      </div>
      <div>
        <div class="border"></div>
      </div>
      <div class="tableList">
        <el-table style="width: 100%" border :data="tableList">
          <el-table-column prop="developStation" label="始发地" align="center">
          </el-table-column>
          <el-table-column
            prop="destinationStation"
            label="目的地"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="amount"
            label="价格(元/吨 不含税)"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </template>
  
  <script>
  import { railwayList, highwayCalcd } from "@/api/hy.js";
  export default {
    data() {
      return {
        formData: {
          destination: "",
          destinationStation: "",
          develop: "",
          developStation: "",
        },
        tableList: [],
        value: "",
        options1: [],
        options2: [],
        optionChildren: [],
        option2Children: [],
      };
    },
  
    mounted() {
      this.getList();
    },
  
    methods: {
      getList() {
        let config = {
          type: 1,
        };
        railwayList(config)
          .then((ret) => {
            if (ret.code === 0) {
              console.log(ret.data);
              this.options1 = ret.data[0].children;
              this.options2 = ret.data[1].children;
            }
          })
          .catch((err) => {});
      },
      /**
       * 铁路发局 change
       */
      developChange(value) {

        let selectItem = this.options1.filter((item) => item.label == value);
        this.optionChildren = selectItem[0].children;
        this.formData.developStation=''
      },
      arriveChange(value) {
        let arriveItem = this.options2.filter((item) => item.label == value);
        this.option2Children = arriveItem[0].children;
        this.formData.destinationStation=''
      },
      //清空
      clear() {
        this.formData.destination = "";
        this.formData.destinationStation = "";
        this.formData.develop = "";
        this.formData.developStation = "";
      },
      check() {
        if (this.formData.develop == '' || this.formData.develop == undefined) {
          this.$message.error("请选择始发地");
          return;
        }
        if (this.formData.destination == '' || this.formData.destination == undefined) {
          this.$message.error("请选择目的地");
          return;
        }
        if (this.formData.developStation == '' || this.formData.developStation == undefined) {
          this.$message.error("请选择起点");
          return;
        }
        if (this.formData.destinationStation == '' || this.formData.destinationStation == undefined) {
          this.$message.error("请选择终点");
          return;
        }
        let config = {
          destination: this.formData.destination,
          destinationStation: this.formData.destinationStation,
          develop: this.formData.develop,
          developStation: this.formData.developStation,
        };
        highwayCalcd(config).then((ret) => {
          console.log(ret,"66666666666");
          if (ret.code === 0) {
            this.tableList = ret.data;
          }
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .Railway {
    width: 65%;
    background: #fff;
    margin: 0 auto;
    padding: 20px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .right_bor {
    padding: 30px 2px;
    text-align: center;
    border: 1px solid #ccc;
    width: 260px;
  }
  .flexAll {
    display: flex;
    // justify-content: space-between;
  }
  .flex2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .Rbtn {
    margin-left:-165px;
    margin-top:20px;
  }
  .border {
    width: 100%;
    height: 1px;
    background-color: #909399;
    margin: 30px 0;
  }
  .tableList {
  }
  </style>